<template>
  <div class="home-elght">
      <div class="title">{{ langvalue.section11.one }}</div>
      <div class="detail">
        <!-- <h2 style="text-align: center;"> 查看实时监控视频，可联系业务员</h2> -->
        <div class="itemlist">
          <div class="item">
            <video id="video" class="videoplay" controls src="https://resources.wukongbc.com/video/kuangchang4.mp4"></video>
            <p>{{ langvalue.other.two }}</p>
          </div>
          <div class="item">
            <video id="video" class="videoplay" controls src="https://resources.wukongbc.com/video/kuangchang3.mp4"></video>
            <p>{{ langvalue.other.three }}</p>
          </div> 
          <div class="item">
            <video id="video" class="videoplay" controls src="https://resources.wukongbc.com/video/kuangchang2.mp4"></video>
            <p>{{ langvalue.other.four }}</p>
          </div> 
          <div class="item">
            <video id="video" class="videoplay" controls src="https://resources.wukongbc.com/video/kuangchang1.mp4"></video>
            <p>{{ langvalue.other.five }}</p>
          </div>
          
        </div>
      </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '@/assets/js/language'
export default {
  name: 'AppHeaderNav',
  setup () {
    const store = useStore()
    // 拿到vuex中的分类列表
    const langtype = store.state.home.type
    const type = ref(langtype)
    // eslint-disable-next-line no-undef
    const langvalue = ref(returnLanguage(langtype))

    const playvideo = () => {
      document.getElementById('video').play()
    }
    const jiankongvideo = () => {
      document.getElementById('video').play()
    }
    watch(() => store.state.home.type, (newVal) => {
      langvalue.value = returnLanguage(newVal)
      type.value = newVal
    }, { immediate: true })
    return { langvalue, playvideo,jiankongvideo, type }
  }
}
</script>
<style scoped lang="less">
.videoplay{
  border-radius: 20px;
}
.home-elght{
  width: 100%;
  margin: 0px auto;
  text-align: center;
  padding: 70px;
}
.title{
 margin: 0 auto;
 font-size: 28px;
 font-weight: bold;
 position: relative;
}
.title::before{
  content: '';
  position: absolute;
  left: calc(50% - 70px);
  bottom: -10px;
  width: 150px;
  height: 3px;
  background: linear-gradient(-90deg, rgba(217, 255, 253, 1), rgba(159, 177, 255, 1));;;
}
.stitle{
  color: #ffffff50;
  font-weight: 500;
  font-size: 18px;
  margin-top: 30px;
}
.detail{
  width: 1240px;
  font-size: 20px;
  font-weight: 300;
  margin: 20px auto;
  padding: 20px 0;
  .itemlist{
    display: flex;
    flex-wrap: wrap;
    .item{
      width: calc(50% - 20px);
      margin: 10px;
      video{
        width: 100%;
      }
    }

  }
}

</style>
